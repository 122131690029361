<template>
	<div class="checkstand">
		<mallHeader />
		<div class="mall-checkstand container">
			<ul class="breadcrumb">
				<li>
					<a href="#" @click="$router.push('/ori-mall')"
						><i class="el-icon-s-home"></i
					></a>
				</li>
				<li><i class="el-icon-arrow-right"></i></li>
				<li><a href="#">收银台</a></li>
			</ul>
			<div class="checkstand-top">
				<ul>
					<li class="cs-top" @click="$router.push('/userInfo')">
						请叫我柯先生
					</li>
					<li>|</li>
					<li class="cs-top">我的订单</li>
					<li>|</li>
					<li class="cs-top">使用帮助</li>
				</ul>
			</div>
			<div class="cs-prompt">
				订单提交成功，请尽快付款！订单号：239306881206
				<span class="cs-price">应付金额<em>1849.00</em></span>
				<br />
				请您在
				<span class="countdown">{{ time }}</span>
				内完成支付，否则订单会被自动取消
				<span class="cs-order-detail" @click="detailArrow = !detailArrow"
					>订单详情<i
						:class="detailArrow ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
					></i
				></span>
			</div>
			<div class="order-detail" v-if="detailArrow === false">
				收货地址：上海青浦区盈浦街道盈港东路8000弄199号302 收货人：*潜 ****6502
				<br />
				商品名称：英特尔（Intel）12代 酷睿 i5-12600KF CPU处理器10核16线程
				单核睿频至高可达4.9Ghz 20M三级缓存
			</div>
			<div class="cs-pay">
				<div class="cs-pay-choose">
					<div class="pay-name">
						应必客支付
					</div>
					<div class="radio-pay">
						<el-radio v-model="radio" label="1"
							><img src="@/assets/images/alipay.png" alt="" /><span
								class="alipay"
								>支付宝</span
							></el-radio
						>
						<el-radio v-model="radio" label="2"
							><img src="@/assets/images/wechat.png" alt="" /><span
								class="wechat"
								>微信</span
							></el-radio
						>
					</div>
				</div>
				<div class="immediate-payment">
					立即支付
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import mallHeader from './header.vue'
export default {
	name: 'product',
	components: {
		mallHeader,
	},
	data() {
		return {
			time: '',
			maxtime: 24 * 60 * 60,
			detailArrow: true, //订单详情
			radio: '1',
		}
	},
	mounted() {
		this.CountDown()
	},
	methods: {
		CountDown() {
			if (this.maxtime >= 0) {
				let hour = Math.floor(this.maxtime / 3600)
				let minutes = Math.floor((this.maxtime / 60) % 60)
				let seconds = Math.floor(this.maxtime % 60)
				let msg =
					this.checkTime(hour) +
					'时' +
					this.checkTime(minutes) +
					'分' +
					this.checkTime(seconds) +
					'秒'
				this.time = msg
				--this.maxtime
			} else {
				clearInterval(timer)
				alert('时间到，结束!')
				this.left = false
				this.right = false
				this.midBottom = false
			}
			var timer = setInterval(() => {
				clearInterval(timer)
				this.CountDown()
			}, 1000)
		},
		checkTime(i) {
			if (i < 10) {
				i = '0' + i
			}
			return i
		},
	},
}
</script>

<style lang="scss" scoped>
.checkstand {
	color: #666;
  font-family: fangsong;
	li {
		list-style: none;
	}
	.container {
		width: 1200px;
		margin: 0 auto;
	}
	.mall-checkstand {
		.breadcrumb {
			margin: 23px 0;
			background-color: transparent;
			padding: 0;
			display: inline-block;
			width: 100%;
			line-height: 100%;
			li {
				display: inline-block;
				padding-left: 8px;
				margin-right: 7px;
				position: relative;
				a {
					display: block;
					padding: 0;
					color: #999;
					text-decoration: none;
					font-size: 14px;
				}
				a:hover {
					color: #005ea6;
				}
				i {
					font-size: 14px;
				}
			}
		}
		.checkstand-top {
			ul {
				display: flex;
				justify-content: flex-end;
				li {
					margin: 0 10px;
					color: #999;
					font-size: 14px;
				}
				.cs-top {
					cursor: pointer;
				}
				.cs-top:hover {
					color: #005ea6;
				}
			}
		}
		.cs-prompt {
			font-size: 16px;
			margin: 40px 0;
		}
		.countdown {
			color: #005ea6;
		}
		.cs-price {
			float: right;
			em {
				font-weight: 700;
				color: #d12;
				margin-left: 10px;
				font-style: normal;
			}
		}
		.cs-order-detail {
			float: right;
			font-size: 14px;
			color: #005ea6;
			cursor: pointer;
		}
		.cs-order-detail:hover {
			color: #d12;
		}
		.order-detail {
			display: block;
			line-height: 30px;
			padding-top: 10px;
			font-size: 14px;
			border-top: 1px solid #d7d7d7;
		}
		.cs-pay {
			height: 500px;
			border-radius: 5px;
			background-color: #fff;
			box-shadow: 0 6px 32px rgb(0 0 0 / 13%);
			padding: 30px 30px 0 30px;
			.cs-pay-choose {
				box-shadow: 0 0 6px rgb(10 22 57 / 13%);
				border-radius: 5px;
				height: 350px;
				position: relative;
				.pay-name {
					position: absolute;
					display: block;
					width: 100px;
					height: 30px;
					border-bottom-right-radius: 15px;
					border-top-left-radius: 5px;
					top: 0;
					left: 0;
					text-align: center;
					line-height: 30px;
					background-color: #005ea6;
					font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial,
						sans-serif;
					color: #fff;
				}
				.radio-pay {
					padding: 80px 20px 0 20px;
					.el-radio {
						padding: 30px 0;
						display: block;
						color: #222;
						font-weight: 700;
						border-bottom: 1px solid #d7d7d7;
						margin: 0;
						.alipay {
							font-size: 18px;
							margin-left: 10px;
						}
						.wechat {
							font-size: 18px;
							margin-left: 10px;
						}
						img {
							margin-left: 20px;
						}
					}
				}
			}
			.immediate-payment {
				height: 50px;
				display: block;
				width: 222px;
				cursor: pointer;
				text-align: center;
				line-height: 50px;
				background-color: #005ea6;
				color: #fff;
				margin-top: 30px;
				border-radius: 5px;
				font-weight: 700;
				float: right;
			}
			.immediate-payment:hover {
				background-color: #d12;
			}
		}
	}
}
</style>
